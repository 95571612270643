import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { debounce } from 'lodash';

import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

import { getAllergens } from '@src/api/allergen';
import { getDailyMenuById } from '@src/api/dailyMenu';
import { getDropoffMenu } from '@src/api/dropoffMenu';
import { getWeeklyMenu } from '@src/api/weeklyMenu';
import { DROP_OFF } from '@src/lib/constants';
import { getOptions, Params } from '@src/lib/getOptions';
import { dateFormatter } from '@src/lib/helper';
import { Category } from '@src/model/Category';
import { Service } from '@src/model/Event';
import { WeeklyMenuOrder } from '@src/model/WeeklyMenu';
import { startLoaderAction, stopLoaderAction } from '@src/redux/action/login';
import { Breakpoints } from '@src/styles/breakpoints';

import { SearchIcon } from '../Icons';

import {
  StyledClearButton,
  StyledContainer,
  StyledSearch,
  StyledSearchBar,
  StyledSearchInput,
  StyledSearchText,
} from './styles';
interface Allergen {
  label: string;
  value: string;
}

const Helmet = ({
  setWeeklyMenu,
  selectedDate,
  activeStep,
  setSearchText,
  eventId,
  serviceType,
  menuId,
}: {
  setWeeklyMenu: Dispatch<SetStateAction<WeeklyMenuOrder | undefined>>;
  selectedDate: string;
  activeStep: number;
  setSearchText: Dispatch<SetStateAction<string>>;
  eventId: string;
  serviceType: Service;
  menuId?: string;
}) => {
  const [allergen, setAllergen] = useState<Allergen | null>(null);
  const inputRef = useRef(null);
  const [showSearchBar, setSearchBar] = useState(false);
  const [allergenData, setAllergenData] = useState<Allergen[]>();
  const dispatch = useDispatch();

  const getDishData = async ({ allergens }: { allergens?: string }) => {
    // TODO: add filter to the api call and fix ingredients
    const dataObj: Params = {
      ...(allergens
        ? {
            filter: {
              allergens: allergens,
              service: serviceType,
              ...(eventId ? { eventId } : {}),
            },
          }
        : {
            ...(eventId
              ? {
                  filter: {
                    eventId,
                    service: serviceType,
                  },
                }
              : {}),
          }),
    };

    if (serviceType == DROP_OFF) {
      try {
        const dropoffMenuResponse = await getDropoffMenu();
        dropoffMenuResponse && setWeeklyMenu(dropoffMenuResponse.data.menu);
      } catch (err) {
        console.error(err);
      }
    } else {
      try {
        if (!menuId) {
          const weeklyMenuRes = await getWeeklyMenu(dateFormatter(selectedDate as string), dataObj);
          setWeeklyMenu(weeklyMenuRes?.data?.data);
        } else if (menuId) {
          const menu = await getDailyMenuById(menuId, dataObj);
          setWeeklyMenu(menu?.data);
        }
      } catch (err) {
        console.error('Error fetching weekly menu:', err);
      }
    }
  };

  const debounceSearch = useCallback(
    debounce((text: string) => {
      const trimmedText = text.trim();
      setSearchText(trimmedText?.toLowerCase());
    }, 300),
    []
  );

  const clearInput = () => {
    if (inputRef.current) {
      //@ts-ignore
      inputRef.current.value = '';
      // search?.onClearSearch();
      setSearchText('');
      getDishData({ allergens: allergen?.value || '' });
      setSearchBar(false);
    }
  };

  const fetchAllergenData = useCallback(async () => {
    const loaderRequest = `unique_${Date.now()}`;

    try {
      dispatch(startLoaderAction(loaderRequest));

      const params = getOptions({});

      const response = await getAllergens(params);

      const result = response?.data?.results?.length ? response?.data?.results : undefined;
      result &&
        setAllergenData(
          result.map((item: { name: string; _id: string }) => ({
            label: item.name,
            value: item._id,
          }))
        );
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(stopLoaderAction(loaderRequest));
    }
  }, []);

  useEffect(() => {
    fetchAllergenData();
  }, []);

  useEffect(() => {
    setAllergen(null);
    setSearchText('');
    getDishData({ allergens: '' });
    clearInput();
  }, [activeStep]);

  return (
    <StyledContainer>
      {!showSearchBar ? (
        <StyledSearch
          onClick={() => {
            setSearchBar(true);
          }}
        >
          <SearchIcon />
          <StyledSearchText placeholder='Search' />
        </StyledSearch>
      ) : null}
      {showSearchBar ? (
        <StyledSearchBar>
          <StyledSearchInput
            type='text'
            placeholder={'Search...'}
            onChange={(event: { target: { value: string } }) => debounceSearch(event.target.value)}
            ref={inputRef}
            autoFocus
          />
          <StyledClearButton onClick={clearInput}>X</StyledClearButton>
        </StyledSearchBar>
      ) : null}
      <Autocomplete
        id='excludeAllergens'
        options={allergenData || []}
        getOptionLabel={option => option.label}
        onChange={(event, value) => {
          setAllergen(value);
          getDishData({ allergens: value?.value });
        }}
        sx={{
          width: 224,
          '& .MuiInputBase-root': {
            height: 40,
            '& .MuiInputBase-input': {
              padding: '0 14px',
              fontSize: '16px',
            },
          },
          '& .MuiInputLabel-root': {
            top: '-7px',
          },
          '& .MuiInputLabel-shrink': {
            top: '0px',
          },
          [Breakpoints.MOBILE]: {
            width: '100%',
          },
        }}
        value={allergen}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        renderInput={params => <TextField {...params} label='Exclude Allergens' placeholder='Exclude Allergens' />}
      />
    </StyledContainer>
  );
};

export default Helmet;
