import * as yup from 'yup';

const updatePAXValidation = (currentMinPersonLimit = 20, maxNumberOfPax?: number) =>
  yup.object({
    numberOfGuests: yup
      .number()
      .integer()
      .min(
        currentMinPersonLimit,
        `For Full Service Buffet Catering, the minimum number of guests must be ${currentMinPersonLimit}. Please adjust the number of guests to proceed`
      )
      .max(
        maxNumberOfPax as number,
        `For Full Service Buffet Catering, the maximum number of guests should be fewer than ${maxNumberOfPax}. Please adjust the number of guests to proceed.`
      )
      .required('Please enter the Number of Guests'),
  });

export { updatePAXValidation };
