import { useState } from 'react';

import { ClickAwayListener, Tooltip } from '@mui/material';
import Modal from '@mui/material/Modal';

import { OpenTooltips } from '@src/components/DishItem';
import { BUFFET } from '@src/lib/constants';
import { getAllergensIcon } from '@src/lib/helper';
import { closeIcon, notFound } from '@src/lib/imgUrl';
import { Box, BoxDish } from '@src/model/Box';
import { Service } from '@src/model/Event';
import { WeeklyAllergens, WeeklyDish } from '@src/model/WeeklyMenu';
import { theme } from '@src/styles/theme';

import {
  StyledAllergensContainer,
  StyledAllergensImgContainer,
  StyledAllergensLeftContainer,
  StyledAllergensRightContainer,
  StyledBrandName,
  StyledContainer,
  StyledDescriptionContainer,
  StyledDishContainer,
  StyledDishImage,
  StyledDishImageContainer,
  StyledDishImgContainer,
  StyledDishNameContainer,
  StyledHeadingContainer,
} from './styles';

interface MoreInfoProps {
  dishData: WeeklyDish | undefined | Box;
  showModal: boolean;
  handleClose: () => void;
  serviceType: Service;
  allergens?: WeeklyAllergens[];
  isBox?: boolean;
}

const MoreInfo = ({ handleClose, showModal, allergens, dishData, serviceType, isBox = false }: MoreInfoProps) => {
  const [openTooltips, setOpenTooltips] = useState<OpenTooltips>({});

  const dishImage = isBox
    ? (dishData as Box).picture
    : (dishData as WeeklyDish)?.pictures?.find(({ serviceType: service }) => {
        return service === serviceType;
      });

  const handleTooltipToggle = (index: number, isOpen: boolean) => {
    setOpenTooltips(prevOpenTooltips => ({
      ...prevOpenTooltips,
      [index]: isOpen,
    }));
  };

  const renderBoxHeader = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <StyledDishImgContainer
          onClick={handleClose}
          src={closeIcon}
          alt='close icon'
          style={{ height: 20, width: 20, padding: 12 }}
        />
        <div style={{ display: 'flex' }}>
          <img
            style={{ overflow: 'hidden', borderRadius: '8px', objectFit: 'cover' }}
            height={165}
            width={275}
            src={dishImage?.url || notFound}
            alt='dish image'
          />

          <div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
                marginLeft: '20px',
                width: '400px',
              }}
            >
              <StyledDishNameContainer>{dishData?.name}</StyledDishNameContainer>
              <StyledDescriptionContainer>{dishData?.description}</StyledDescriptionContainer>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '80%' }}>
                <div
                  style={{
                    fontFamily: theme.fontFamily.lato,
                    fontSize: '14px',
                    fontWeight: 600,
                  }}
                >
                  Number of Items in the box :{' '}
                  {(dishData as Box)?.dishes?.reduce((acc, dish) => acc + dish.itemCount, 0)}
                </div>
                <StyledAllergensContainer>
                  {allergens?.length ? (
                    <StyledAllergensLeftContainer>
                      {allergens?.map((allergen, index) => {
                        return (
                          <ClickAwayListener onClickAway={() => handleTooltipToggle(index, false)}>
                            <Tooltip
                              key={`${allergen?._id}+${index}`}
                              title={allergen?.name}
                              placement='right-start'
                              onClose={() => handleTooltipToggle(index, false)}
                              open={openTooltips[index] || false}
                              onMouseEnter={() => handleTooltipToggle(index, true)}
                              onMouseLeave={() => handleTooltipToggle(index, false)}
                            >
                              <StyledAllergensImgContainer
                                style={{ width: '24px', height: '24px' }}
                                onClick={() => handleTooltipToggle(index, true)}
                                src={allergen?.icon?.url || getAllergensIcon(allergen?.name)}
                                alt={allergen?._id}
                              />
                            </Tooltip>
                          </ClickAwayListener>
                        );
                      })}
                    </StyledAllergensLeftContainer>
                  ) : null}
                </StyledAllergensContainer>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderDishHeader = () => {
    return (
      <>
        <StyledDishContainer>
          <StyledDishNameContainer>{dishData?.name}</StyledDishNameContainer>
          <StyledDishImgContainer onClick={handleClose} src={closeIcon} alt='close icon' />
        </StyledDishContainer>
        <StyledDishImage>
          <StyledDishImageContainer src={dishImage?.url || notFound} alt='dish image' />
          {(dishData as WeeklyDish)?.byBrand && (
            <StyledBrandName>by {(dishData as WeeklyDish)?.byBrand}</StyledBrandName>
          )}
        </StyledDishImage>
      </>
    );
  };

  return (
    <Modal
      open={showModal}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <StyledContainer style={{ height: isBox ? '100%' : '' }}>
        {isBox ? renderBoxHeader() : renderDishHeader()}
        {!isBox && (
          <StyledAllergensContainer>
            {allergens?.length ? (
              <StyledAllergensLeftContainer>
                {allergens?.map((allergen, index) => {
                  return (
                    <ClickAwayListener onClickAway={() => handleTooltipToggle(index, false)}>
                      <Tooltip
                        key={`${allergen?._id}+${index}`}
                        title={allergen?.name}
                        placement='right-start'
                        onClose={() => handleTooltipToggle(index, false)}
                        open={openTooltips[index] || false}
                        onMouseEnter={() => handleTooltipToggle(index, true)}
                        onMouseLeave={() => handleTooltipToggle(index, false)}
                      >
                        <StyledAllergensImgContainer
                          onClick={() => handleTooltipToggle(index, true)}
                          src={allergen?.icon?.url || getAllergensIcon(allergen?.name)}
                          alt={allergen?._id}
                        />
                      </Tooltip>
                    </ClickAwayListener>
                  );
                })}
              </StyledAllergensLeftContainer>
            ) : null}
            <StyledAllergensRightContainer>Actual product may differ from image</StyledAllergensRightContainer>
          </StyledAllergensContainer>
        )}
        {!isBox && <StyledDescriptionContainer>{dishData?.description}</StyledDescriptionContainer>}
        {!isBox && (dishData as WeeklyDish)?.ingredients?.length ? (
          <div>
            <StyledHeadingContainer>Ingredients:</StyledHeadingContainer>
            <StyledDescriptionContainer>
              {(dishData as WeeklyDish)?.ingredients?.map(item => item?.name).join(', ')}.
            </StyledDescriptionContainer>
          </div>
        ) : null}
        {!isBox && (
          <StyledHeadingContainer>
            Once serving contains about{' '}
            {serviceType === BUFFET ? (dishData as WeeklyDish)?.quantity : (dishData as WeeklyDish)?.dropOffQuantity}gm
          </StyledHeadingContainer>
        )}
        {isBox ? (
          <div
            style={{
              fontFamily: theme.fontFamily.lato,
              fontSize: '18px',
              fontWeight: 600,
              color: theme.primaryColor.deepNavy,
            }}
          >
            Whats included in this Box
          </div>
        ) : null}
        {isBox ? (
          <div>
            {(dishData as Box)?.dishes?.map(dish => {
              return (
                <div style={{ padding: '22px 12px' }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '30px',
                      marginBottom: '12px',
                    }}
                  >
                    <img
                      style={{ overflow: 'hidden', borderRadius: '8px' }}
                      height={95}
                      width={190}
                      src={dish?.dishId?.pictures?.[1]?.url || notFound}
                      alt='dish image'
                    />
                    <div style={{ display: 'flex', flexDirection: 'column', rowGap: '16px', width: '320px' }}>
                      <div style={{ fontFamily: theme.fontFamily.lato, fontSize: '16px', fontWeight: 600 }}>
                        {dish?.dishId?.name}
                      </div>
                      <StyledAllergensContainer>
                        {dish?.dishId?.allergens?.length ? (
                          <StyledAllergensLeftContainer>
                            {dish?.dishId?.allergens?.map((allergen, index) => {
                              return (
                                <StyledAllergensImgContainer
                                  style={{
                                    width: '24px',
                                    height: '24px',
                                  }}
                                  onClick={() => handleTooltipToggle(index, true)}
                                  src={allergen?.icon?.url || getAllergensIcon(allergen?.name)}
                                  alt={allergen?._id}
                                />
                              );
                            })}
                          </StyledAllergensLeftContainer>
                        ) : null}
                      </StyledAllergensContainer>
                      <div
                        style={{ fontFamily: theme.fontFamily.lato, fontSize: '14px', color: theme.primaryColor.gray }}
                      >
                        {dish?.dishId?.description}
                      </div>
                    </div>
                    <div>
                      <div
                        style={{
                          fontFamily: theme.fontFamily.lato,
                          fontSize: '20px',
                          fontWeight: 600,
                          color: theme.primaryColor.navyBlue,
                        }}
                      >
                        {dish?.itemCount}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : null}
      </StyledContainer>
    </Modal>
  );
};

export default MoreInfo;
