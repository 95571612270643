import styled from 'styled-components';

import { Breakpoints } from '@src/styles/breakpoints';

export const StyledResendTextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 17px;
  justify-content: center;
  margin-top: 16px;
  text-align: center;
  ${Breakpoints.MOBILE} {
    margin: 0px;
  }
`;

export const StyledDash = styled.div`
  width: 43.83px;
  height: 0px;
  border-top: 1px solid ${props => props.theme.primaryColor.sereneSky};
`;

export const StyledCountDownContainer = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  color: ${props => props.theme.primaryColor.brandPaletteGray};
`;

export const StyledResendText = styled.span`
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
  color: ${props => props.theme.primaryColor.cyanGreen};
`;

export const StyledButton = styled.button<{ loginColor?: boolean; cursorPointer?: boolean; disabled?: boolean }>`
  width: 100%;
  margin-top: 16px;
  padding: 12px 34px;
  background-color: ${({ theme }) => theme.primaryColor.shadePurple};
  color: ${({ theme }) => theme.primaryColor.white};
  border: none;
  border-radius: 8px;
  ${({ disabled, theme }) => (disabled ? `background-color:${theme.primaryColor.lightGray2};` : `cursor: pointer;`)}
  ${Breakpoints.MOBILE} {
    margin: 0px;
  }
`;
