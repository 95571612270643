/* eslint-disable complexity */
import { Divider } from '@mui/material';

import DishItem from '@src/components/DishItem';
import EmptyCard from '@src/components/EmptyCard';
import { BUFFET, DROP_OFF } from '@src/lib/constants';
import { isBoxCategory } from '@src/lib/helper';
import { notFound } from '@src/lib/imgUrl';
import { Box } from '@src/model/Box';
import { Category } from '@src/model/Category';
import { AddOn, Dish, Service } from '@src/model/Event';
import { WeeklyDish } from '@src/model/WeeklyMenu';
import { theme } from '@src/styles/theme';

import { StyledAccordionContainer, StyledDishesContainer, StyledFoodContainer, StyledItemCategory } from './styles';

interface DropOffFoodContainerProps {
  allDishes: Record<string, Record<string, WeeklyDish[]>> | undefined;
  serviceType: Service;
  selectedDish?: Dish[] | AddOn[] | Box[];
  mealDetails?: Category;
  handleDishItemCount?: ({ id, itemCount, category }: { itemCount: number; id: string; category: string }) => void;
  handleCheckbox?: ({
    isChecked,
    id,
    selectItemCount,
    category,
  }: {
    isChecked: boolean;
    id: string;
    selectItemCount?: number;
    category: string;
  }) => void;
  isRateUs?: boolean;
  getDishItemCount?: (id: string, category: string) => number;
  hasAccordion?: boolean;
  reFetchData?: () => Promise<void>;
}

export const DropoffFoodContainer = ({
  allDishes,
  mealDetails,
  selectedDish: selectedDishes,
  handleCheckbox,
  handleDishItemCount,
  getDishItemCount,
  isRateUs = true,
  hasAccordion = false,
  serviceType,
  reFetchData,
}: DropOffFoodContainerProps) => {
  const DishesPerSubCategory = ({ categoryName }: { categoryName: string }) => {
    return (
      <StyledDishesContainer>
        {allDishes &&
          categoryName &&
          allDishes[categoryName] &&
          Object.entries(allDishes[categoryName]).map(([subCategoryName, dishes], index) => {
            return (
              <div>
                <StyledItemCategory
                  style={{
                    color: theme.primaryColor.midnight,
                    marginBottom: '8px',
                  }}
                >
                  <p>{subCategoryName === 'noSub' ? '' : subCategoryName}</p>
                </StyledItemCategory>
                {dishes &&
                  dishes.map(dish => {
                    const {
                      description,
                      minimum,
                      multiplyBy,
                      price,
                      dropOffPrice,
                      name,
                      byBrand,
                      pictures,
                      allergens,
                      averageRating,
                      totalFeedbacks,
                      dropOffMinimum,
                      category,
                    } = dish || {};

                    const image = isBoxCategory(dish?.category?.name)
                      ? (dish as Box)?.picture
                      : pictures &&
                        pictures?.find(({ serviceType: service }) => {
                          return service === DROP_OFF;
                        });

                    const dishPrice =
                      category && (category?.name === 'Add-On' || category?.name === 'Boxes') ? price : dropOffPrice;

                    return (
                      <div style={{ margin: '16px 0' }}>
                        {dish && dish._id && (
                          <DishItem
                            key={dish._id}
                            dishID={dish._id}
                            dishName={name}
                            dishPrice={dishPrice || 0}
                            dishBrand={byBrand}
                            dishImage={image?.url || notFound}
                            dishInfo={description}
                            minQuantity={serviceType === BUFFET ? minimum : dropOffMinimum ?? 1}
                            allergens={allergens ?? []}
                            multiplyBy={multiplyBy ?? 1}
                            dishCount={getDishItemCount?.(dish._id, categoryName) ?? 0}
                            mealDetails={mealDetails}
                            dishCategory={categoryName}
                            isRateUs={isRateUs}
                            selectedDish={selectedDishes}
                            handleDishItemCount={handleDishItemCount}
                            handleCheckbox={handleCheckbox}
                            averageRating={averageRating}
                            totalFeedbacks={totalFeedbacks}
                            serviceType={serviceType}
                            reFetchData={reFetchData}
                          />
                        )}
                        {isRateUs && index + 1 !== dishes.length ? (
                          <Divider style={{ margin: '0 12px', borderStyle: 'dashed' }} />
                        ) : null}
                      </div>
                    );
                  })}
              </div>
            );
          })}
      </StyledDishesContainer>
    );
  };

  return (
    <StyledFoodContainer>
      {allDishes ? (
        Object.entries(allDishes).map(([categoryName, dishes], index) => {
          return dishes && Object.entries(dishes)?.length ? (
            hasAccordion ? (
              <StyledAccordionContainer style={{ backgroundColor: 'white' }}>
                <StyledItemCategory
                  className='dishScroll'
                  id={`dish${index}`}
                  style={{
                    overflow: 'hidden',
                    height: '0px',
                  }}
                >
                  {categoryName}
                </StyledItemCategory>
                <DishesPerSubCategory categoryName={categoryName} />
              </StyledAccordionContainer>
            ) : (
              <div>
                {categoryName !== 'noSub' && categoryName !== 'selected' ? (
                  <div>
                    <StyledItemCategory
                      className='dishScroll'
                      id={`dish${index}`}
                      style={{
                        overflow: 'hidden',
                        height: 0,
                      }}
                    >
                      {categoryName}
                    </StyledItemCategory>
                    <DishesPerSubCategory categoryName={categoryName} />
                  </div>
                ) : null}
              </div>
            )
          ) : (
            <EmptyCard desc={'No item found'} />
          );
        })
      ) : (
        <EmptyCard desc={'No item found'} />
      )}
    </StyledFoodContainer>
  );
};
