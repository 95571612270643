import apiEndpoints from '@src/lib/apiEndpoints';
import { Params } from '@src/lib/getOptions';
import { DataProps } from '@src/lib/getUpdate';
import { Category } from '@src/model/Category';

import apiRequest from './api';

export const getCategory = (id: string) => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.GET_CATEGORY}/${id}`,
  });
};

export const createCategory = (data: Category) => {
  return apiRequest({
    method: 'POST',
    url: `${apiEndpoints.CREATE_CATEGORY}`,
    data,
  });
};

export const updateCategory = (data: DataProps, id?: string) => {
  return apiRequest({
    method: 'PUT',
    url: `${apiEndpoints.UPDATE_CATEGORY}/${id}`,
    data,
  });
};

export const getCategories = (params: Params) => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.CATEGORY_LIST}`,
    config: { params },
  });
};

export const deleteCategory = (data: DataProps) => {
  return apiRequest({
    method: 'DELETE',
    url: `${apiEndpoints.DELETE_CATEGORY}`,
    data,
  });
};
