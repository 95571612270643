import apiEndpoints from '@src/lib/apiEndpoints';
import { Params } from '@src/lib/getOptions';
import { DataProps } from '@src/lib/getUpdate';
import { Menu } from '@src/model/Menu';

import apiRequest from './api';

export const getAdminDailyMenuById = (id: string, data?: Params) => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.ADMIN_DAILY_MENU_BY_ID}/${id}`,
    config: { params: data },
  });
};

export const getDailyMenuById = (id: string, data?: Params) => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.DAILY_MENU_BY_ID}/${id}`,
    config: { params: data },
  });
};

export const getDailyMenus = (params: Params) => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.DAILY_MENU_LIST}`,
    config: { params },
  });
};

export const createMenu = (data: Menu) => {
  return apiRequest({
    method: 'POST',
    url: `${apiEndpoints.CREATE_DAILY_MENU}`,
    data,
  });
};

export const updateMenu = (data: DataProps, id: string) => {
  return apiRequest({
    method: 'PUT',
    url: `${apiEndpoints.DAILY_MENU}/${id}`,
    data,
  });
};

export const updateMenuStatus = (data: any) => {
  return apiRequest({
    method: 'PUT',
    url: `${apiEndpoints.UPDATE_DAILY_MENU_STATUS}`,
    data,
  });
};
