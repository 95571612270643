import { useEffect, useState } from 'react';

function useMediaQuery(innerWidth = 992) {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > innerWidth);

  const checkScreenSize = () => {
    setIsDesktop(window.innerWidth > innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', checkScreenSize);
    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  return isDesktop;
}

export default useMediaQuery;
