const FoodBasicIcon = ({ height = '80', width = '80' }: { height?: string; width?: string }) => (
  <svg width={width} height={height} viewBox='0 0 80 80' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M40.9327 13.6749L42.5661 18.6559H47.8519C48.0588 18.6559 48.2604 18.7208 48.4278 18.8413C48.5952 18.9618 48.7198 19.1317 48.7837 19.3267C48.8477 19.5216 48.8477 19.7317 48.7838 19.9266C48.7199 20.1216 48.5953 20.2915 48.4279 20.412L44.1514 23.4907L45.7849 28.4718C45.8489 28.6667 45.8489 28.8768 45.785 29.0718C45.721 29.2668 45.5964 29.4367 45.429 29.5572C45.2616 29.6777 45.06 29.7426 44.853 29.7425C44.6461 29.7425 44.4445 29.6775 44.2772 29.557L40.0008 26.4783L35.7245 29.557C35.5571 29.6775 35.3555 29.7425 35.1486 29.7425C34.9417 29.7426 34.74 29.6777 34.5726 29.5572C34.4052 29.4367 34.2806 29.2668 34.2167 29.0718C34.1527 28.8768 34.1528 28.6667 34.2167 28.4718L35.8502 23.4904L31.5737 20.4117C31.4063 20.2912 31.2818 20.1213 31.2178 19.9263C31.1539 19.7313 31.1539 19.5213 31.2179 19.3264C31.2818 19.1314 31.4064 18.9615 31.5738 18.841C31.7412 18.7205 31.9428 18.6556 32.1497 18.6556H37.4355L39.0689 13.6746C39.1329 13.4796 39.2575 13.3097 39.425 13.1892C39.5924 13.0687 39.794 13.0039 40.0009 13.0039C40.2078 13.0039 40.4094 13.0689 40.5768 13.1894C40.7442 13.31 40.8688 13.4799 40.9327 13.6749Z'
      fill='#FCB326'
    />
    <g clipPath='url(#clip0_930_15233)'>
      <path
        d='M42.1087 44.2739C42.2725 43.9222 42.3705 43.5345 42.3705 43.1216C42.3705 41.6071 41.1386 40.375 39.6239 40.375C38.1095 40.375 36.8773 41.6071 36.8773 43.1216C36.8773 43.5345 36.9753 43.9222 37.1394 44.2739C31.0439 45.3937 26.3564 50.5445 25.9375 56.8545H53.3106C52.8914 50.5445 48.2041 45.3937 42.1087 44.2739ZM39.6239 44.0371C39.1189 44.0371 38.7084 43.6268 38.7084 43.1216C38.7084 42.6164 39.1189 42.2061 39.6239 42.2061C40.1291 42.2061 40.5394 42.6164 40.5394 43.1216C40.5394 43.6268 40.1291 44.0371 39.6239 44.0371Z'
        fill='#255573'
      />
      <path
        d='M24 58.6875C24.4253 60.7744 26.4288 62.3496 28.6391 62.3496H50.6118C52.8224 62.3496 54.8247 60.7744 55.25 58.6875H24Z'
        fill='#255573'
      />
    </g>
    <defs>
      <clipPath id='clip0_930_15233'>
        <rect width='31.25' height='31.25' fill='white' transform='translate(24 35.7344)' />
      </clipPath>
    </defs>
  </svg>
);

export default FoodBasicIcon;
