import styled from 'styled-components';

import { Breakpoints } from '@src/styles/breakpoints';

export const StyledForm = styled.form`
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  width: 50%;
  transform: translate(-50%, -50%);
  flex-direction: column;
  gap: 40px;
  padding: 40px;
  background-color: white;
  border-radius: 16px;
  &:focus {
    outline: none;
  }
  ${Breakpoints.DESKTOP_SMALL} {
    width: 80%;
    padding: 30px;
  }
  ${Breakpoints.TABLET_SMALL} {
    width: 75%;
    padding: 25px;
  } ;
`;

export const StyledHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const StyledHeading = styled.div`
  color: var(--Brand-Palette-Primary-700, #163345);
  font-family: 'the-seasons', Lato;
  font-size: 24px;
  font-weight: 600;
  ${Breakpoints.TABLET_SMALL} {
    font-size: 18px;
  }
`;

export const StyledClose = styled.img`
  width: 16px;
  height: 16px;
  cursor: pointer;
`;

export const StyledInputWrapper = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px 24px;

  ${Breakpoints.TABLET_SMALL} {
    grid-template-columns: 1fr;
    gap: 20px;
  }
`;

export const StyledContainerBottom = styled.div`
  display: flex;
  justify-content: end;
  gap: 16px;
`;

export const StyledErrorMessage = styled.div`
  color: red;
  font-size: 12px;
  margin: -20px 0px 16px;
  min-height: 30px;
`;

export const StyledMessageContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 16px;
  justify-content: space-between;
  ${Breakpoints.TABLET_SMALL} {
    flex-direction: column;
  }
`;

export const StyledMessage = styled.div`
  font-size: 14px;
  color: ${props => props.theme.primaryColor.darkYellow};
  margin-top: auto;
`;

export const StyledSpan = styled.span`
  display: block;
  ${Breakpoints.DESKTOP_LARGE} {
    display: inline;
  }
`;
