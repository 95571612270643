import styled from 'styled-components';

import { Breakpoints } from '@src/styles/breakpoints';

export const StyledContainer = styled.div`
  padding: 2%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 16px;
  &:focus {
    outline: none;
  }
  max-height: 80vh;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  max-width: 700px;
  ${Breakpoints.DESKTOP} {
    width: 65%;
  }
  ${Breakpoints.TABLET} {
    width: 80%;
    padding: 4%;
  }
  ${Breakpoints.MOBILE} {
    width: 80%;
    padding: 5%;
    max-height: 65vh;
  }
`;

export const StyledDishContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
`;

export const StyledDishNameContainer = styled.div`
  font-family: 'the-seasons', Lato;
  font-weight: 700;
  font-size: 24px;
  letter-spacing: -1.2%;
`;

export const StyledDishImgContainer = styled.img`
  cursor: pointer;
`;
export const StyledDishImageContainer = styled.img`
  width: 100%;
  border-radius: 16px;
  aspect-ratio: 620 / 360;
`;

export const StyledAllergensContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  flex-wrap: wrap;
`;
export const StyledAllergensLeftContainer = styled.div`
  display: flex;
  gap: 12px;
`;

export const StyledAllergensRightContainer = styled.div`
  color: ${({ theme }) => theme.primaryColor.mutedGray};
  font-size: 12px;
`;

export const StyledAllergensImgContainer = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 50%;
`;
export const StyledDescriptionContainer = styled.div`
  line-height: 22.4px;
  font-size: 16px;
  color: ${({ theme }) => theme.primaryColor.mutedGray};
`;

export const StyledHeadingContainer = styled.div`
  font-weight: 700px;
  line-height: 25.4px;
  margin-bottom: 12px;
  font-size: 14px;
`;

export const StyledDishImage = styled.div`
  position: relative;
`;
export const StyledBrandName = styled.div`
  color: ${props => props.theme?.primaryColor.shadePurple};
  background-color: ${props => props.theme?.primaryColor.white};
  padding: 4px 6px;
  position: absolute;
  bottom: 8%;
  left: 3%;
  border-radius: 4px;
  font-size: 10px;
  font-weight: 500;
  margin-right: 10px;
`;
