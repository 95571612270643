import styled from 'styled-components';

import { Breakpoints } from '@src/styles/breakpoints';

export const StyledMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  border: 1px solid ${props => props.theme.primaryColor.lunarElegance};
  padding: 40px;
  border-radius: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${({ theme }) => theme.primaryColor.white};
  &:focus {
    outline: none;
  }
  ${Breakpoints.DESKTOP} {
    width: 65%;
    padding: 30px;
  }
  ${Breakpoints.TABLET} {
    width: 80%;
    padding: 25px;
  }
  ${Breakpoints.MOBILE} {
    width: 80%;
    padding: 20px;
  }
`;
export const StyledHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const StyledHeading = styled.div`
  color: ${props => props.theme.primaryColor.twilightBlue};
  font-family: ${props => props.theme.fontFamily.seasons};
  font-size: 24px;
  font-weight: 400;
  letter-spacing: -1.2px;
  ${Breakpoints.MOBILE} {
    font-size: 20px;
  }
`;
export const StyledClose = styled.img`
  width: 16px;
  height: 16px;
  cursor: pointer;
`;
export const StyledMessage = styled.div<{ error?: boolean }>`
  color: ${({ theme, error }) => (error ? theme.primaryColor.red : theme.primaryColor.royalGray)};
  font-size: 14px;
  font-weight: 600;
  line-height: 18.5px;
  font-family: ${props => props.theme.fontFamily.lato};
  ${Breakpoints.MOBILE} {
    font-size: 13px;
  }
`;

export const StyledInputs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: 100%;
`;

export const StyledNumberInput = styled.div`
  width: 100%;
  height: 50%;
`;

export const StyledContainerBottom = styled.div`
  display: flex;
  width: 100%;
  justify-content: end;
  gap: 16px;
  padding-top: 16px;
  flex-wrap: wrap-reverse;
`;
