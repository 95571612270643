import apiEndpoints from '@src/lib/apiEndpoints';
import { Params } from '@src/lib/getOptions';
import { DataProps, DeleteBodySchema } from '@src/lib/getUpdate';
import { Box } from '@src/model/Box';

import apiRequest from './api';

export const createBox = (data: Box) => {
  return apiRequest({
    method: 'POST',
    url: `${apiEndpoints.CREATE_BOX}`,
    data,
  });
};

export const getBoxes = (data: any) => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.GET_BOXES}`,
    data,
  });
};

export const getBoxById = (id: string, params?: Params) => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.GET_BOX_BY_ID}/${id}`,
    config: { params },
  });
};

export const updateBoxById = (data: DataProps, id: string) => {
  return apiRequest({
    method: 'PUT',
    url: `${apiEndpoints.UPDATE_BOX_BY_ID}/${id}`,
    data,
  });
};

export const updateBoxStatus = (data: DeleteBodySchema, status: boolean, doubleConfirmed: boolean) => {
  return apiRequest({
    method: 'PUT',
    url: `${apiEndpoints.UPDATE_BOX_STATUS}`,
    data: { ...data, status, doubleConfirmed },
  });
};
