const EditIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
    <path
      d='M9.1665 1.66666H7.49984C3.33317 1.66666 1.6665 3.33332 1.6665 7.49999V12.5C1.6665 16.6667 3.33317 18.3333 7.49984 18.3333H12.4998C16.6665 18.3333 18.3332 16.6667 18.3332 12.5V10.8333'
      stroke='#4B5563'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M13.3666 2.51666L6.7999 9.08333C6.5499 9.33333 6.2999 9.825 6.2499 10.1833L5.89157 12.6917C5.75823 13.6 6.3999 14.2333 7.30823 14.1083L9.81657 13.75C10.1666 13.7 10.6582 13.45 10.9166 13.2L17.4832 6.63333C18.6166 5.5 19.1499 4.18333 17.4832 2.51666C15.8166 0.849997 14.4999 1.38333 13.3666 2.51666Z'
      stroke='#4B5563'
      strokeWidth='1.5'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12.4248 3.45834C12.9831 5.45001 14.5415 7.00834 16.5415 7.57501'
      stroke='#4B5563'
      strokeWidth='1.5'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default EditIcon;
