/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ForkEffect, takeLatest } from 'redux-saga/effects';

import ActionConstants from '../constants/';

import workerSaga from './workerSaga';

// ------------------------------------
// Watchers
// ------------------------------------
export default function* loginWatcher(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(ActionConstants.USER_DETAILS_ACTION, workerSaga);
  yield takeLatest(ActionConstants.USER_LOGOUT_ACTION, workerSaga);
  yield takeLatest(ActionConstants.REFRESH_TOKEN_ACTION, workerSaga);
}
