const ToggleIcon = ({ isToggled, onClick }: { isToggled: boolean; onClick: () => void }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' onClick={onClick}>
      <path
        d='M10 4H14C18.42 4 22 7.58 22 12C22 16.42 18.42 20 14 20H10C5.58 20 2 16.42 2 12C2 7.58 5.58 4 10 4Z'
        fill={isToggled ? '#3790B3' : 'none'}
        stroke={isToggled ? '#3790B3' : '#4B5563'}
        strokeWidth={isToggled ? '1.5' : 'none'}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d={
          isToggled
            ? 'M14 16C16.2091 16 18 14.2091 18 12C18 9.79086 16.2091 8 14 8C11.7909 8 10 9.79086 10 12C10 14.2091 11.7909 16 14 16Z'
            : 'M10 16C12.2091 16 14 14.2091 14 12C14 9.79086 12.2091 8 10 8C7.79086 8 6 9.79086 6 12C6 14.2091 7.79086 16 10 16Z'
        }
        stroke={isToggled ? 'white' : '#4B5563'}
        strokeWidth={isToggled ? '1.5' : 'none'}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default ToggleIcon;
