import styled from 'styled-components';

import { Breakpoints } from '@src/styles/breakpoints';

export const StyledOuterContainer = styled.div<{ $isMarginBottom?: boolean }>`
  display: flex;
  max-width: 100vw;
  justify-content: center;
  flex: 1;
  width: 100%;
  ${({ $isMarginBottom }) =>
    $isMarginBottom
      ? `
      margin-bottom: 96px;
      ${Breakpoints.TABLET}{
        margin-bottom: 146px;
      }
      ${Breakpoints.MOBILE}{
        margin-bottom: 120px;
      }
    `
      : ''}
`;

export const StyledInnerContainer = styled.div`
  display: flex;
  max-width: 1280px;
  width: 100%;
`;
