import apiEndpoints from '@src/lib/apiEndpoints';

import apiRequest from './api';

export const getDropoffMenu = async () => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.GET_DROPOFF_MENU}`,
  });
};
