import { theme } from '@src/styles/theme';

const AddItemIcon = ({ isSelected = false, isHovered = false }: { isSelected: boolean; isHovered: boolean }) => {
  return (
    <svg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_414_6032)'>
        <path
          d='M7.33301 14.6666H4.97717C2.87801 14.6666 1.83301 13.6216 1.83301 11.5224V4.97742C1.83301 2.87825 2.87801 1.83325 4.97717 1.83325H9.16634C11.2655 1.83325 12.3105 2.87825 12.3105 4.97742'
          stroke={isHovered || isSelected ? theme.primaryColor.twilightBlue : theme.primaryColor.whiteSmoke}
          strokeWidth='1.375'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M17.0228 20.1666H12.8336C10.7345 20.1666 9.68945 19.1216 9.68945 17.0224V10.4774C9.68945 8.37825 10.7345 7.33325 12.8336 7.33325H17.0228C19.122 7.33325 20.167 8.37825 20.167 10.4774V17.0224C20.167 19.1216 19.122 20.1666 17.0228 20.1666Z'
          stroke={isHovered || isSelected ? theme.primaryColor.twilightBlue : theme.primaryColor.whiteSmoke}
          strokeWidth='1.375'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M13.6309 13.75H16.6192'
          stroke={isHovered || isSelected ? theme.primaryColor.twilightBlue : theme.primaryColor.whiteSmoke}
          strokeWidth='1.375'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M15.125 15.2442V12.2559'
          stroke={isHovered || isSelected ? theme.primaryColor.twilightBlue : theme.primaryColor.whiteSmoke}
          strokeWidth='1.375'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_414_6032'>
          <rect width='22' height='22' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AddItemIcon;
