import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { enqueueSnackbar } from 'notistack';

import Modal from '@mui/material/Modal';

import { StyledButton, StyledInput } from '@src/components/FormComponent/styles';
import { FormValues } from '@src/components/FormComponent/types';
import { useIsMobile } from '@src/hooks/useIsMobile';
import { handleRemoveNegativeNumbers } from '@src/lib/helper';
import { closeIcon } from '@src/lib/imgUrl';
import { Settings } from '@src/model/Setting';
import { theme } from '@src/styles/theme';
import { updatePAXValidation } from '@src/validations/updatePAXModalValidation';

import {
  StyledClose,
  StyledContainerBottom,
  StyledErrorMessage,
  StyledForm,
  StyledHeading,
  StyledInputWrapper,
  StyledMessageContainer,
} from '../EventDetailModal/styles';

import { StyledHeaderContainer } from './styles';

interface ModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  onSave: (paxNum: number) => Promise<void>;
  settings?: Settings;
  errorMessage?: string;
  numberOfGuests?: string;
}

export interface Date {
  startDate: string;
  endDate: string;
}

const UpdatePAXModal: React.FC<ModalProps> = ({
  isOpen,
  onRequestClose,
  onSave,
  settings,
  errorMessage,
  numberOfGuests,
}) => {
  const [maxNumberOfPax, setMaxNumberOfPax] = useState<number>();

  const onHandleSubmit = async (values: FormValues) => {
    if (values?.numberOfGuests) {
      onSave(Number(values?.numberOfGuests));
    } else {
      enqueueSnackbar('Please enter all the required fields', {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  const handleClose = () => {
    onRequestClose();
  };

  useEffect(() => {
    if (settings?.defaultMaximumService) {
      const { breakfast, lunch, break: breakCapacity } = settings.defaultMaximumService;
      setMaxNumberOfPax(Math.max(breakfast, lunch, breakCapacity));
    }
  }, [settings]);

  const isMobile = useIsMobile();

  return (
    <Modal
      open={isOpen}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      sx={{
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          backdropFilter: 'blur(5px)',
        },
      }}
    >
      <div>
        <Formik
          enableReinitialize
          initialValues={{ numberOfGuests: numberOfGuests }}
          validationSchema={updatePAXValidation(1, maxNumberOfPax)}
          onSubmit={onHandleSubmit}
        >
          {({ handleSubmit, values, handleBlur, errors, setFieldValue }) => {
            return (
              <StyledForm style={{ width: isMobile ? '' : '25%' }} onSubmit={handleSubmit}>
                <StyledHeaderContainer>
                  <StyledHeading>Update Number of Guests</StyledHeading>
                  <StyledClose src={closeIcon} onClick={handleClose} />
                </StyledHeaderContainer>
                <StyledInputWrapper>
                  <StyledInput
                    onFocus={event =>
                      event.target.addEventListener(
                        'wheel',
                        function (e) {
                          e.preventDefault();
                        },
                        { passive: false }
                      )
                    }
                    onChange={e => {
                      setFieldValue('numberOfGuests', handleRemoveNegativeNumbers(e.target.value));
                    }}
                    value={values.numberOfGuests}
                    onBlur={handleBlur}
                    id='numberOfGuests'
                    type='number'
                    label='Number of Guests'
                    required
                    size='small'
                    InputLabelProps={{
                      shrink: values.numberOfGuests !== undefined && values.numberOfGuests !== '' && true,
                    }}
                    onKeyDown={e => {
                      if (e.key === 'e' || e.key === 'E' || e.key === '-' || e.key === '+') {
                        e.preventDefault();
                      }
                    }}
                  />
                </StyledInputWrapper>
                <div>
                  {errorMessage && <StyledErrorMessage>{errors.numberOfGuests}</StyledErrorMessage>}
                  <StyledMessageContainer>
                    <StyledContainerBottom>
                      <StyledButton
                        border={true}
                        type='button'
                        $bgColor={theme.primaryColor.white}
                        color={theme.primaryColor.black}
                        onClick={handleClose}
                      >
                        Cancel
                      </StyledButton>
                      <StyledButton
                        $isBlueButton
                        type='submit'
                        disabled={!values.numberOfGuests || !!Object.keys(errors).length}
                      >
                        Update
                      </StyledButton>
                    </StyledContainerBottom>
                  </StyledMessageContainer>
                </div>
              </StyledForm>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

export default UpdatePAXModal;
