import { loader } from '@src/lib/imgUrl';

import { StyledImageContainer, StyledLoadingText, StyledModal, StyledModalDialog, StyledOverlay } from './styles';

const Loader = (props: { message?: string }): JSX.Element => {
  const { message } = props;

  return (
    <>
      <StyledOverlay />
      <StyledModal>
        <StyledModalDialog>
          <StyledImageContainer>
            <img src={loader} alt='loader' />
          </StyledImageContainer>
          <StyledLoadingText>{message || 'Loading...'}</StyledLoadingText>
        </StyledModalDialog>
      </StyledModal>
    </>
  );
};

export default Loader;
