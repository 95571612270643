import { CSSProperties, ReactElement, ReactNode } from 'react';

import { StyledInnerContainer, StyledOuterContainer } from './styles';

interface MaxWidthContainerProps {
  children?: ReactElement | ReactElement[] | ReactNode;
  extraStyle?: CSSProperties;
  isMarginBottom?: boolean;
}

const MaxWidthContainer: React.FC<MaxWidthContainerProps> = ({ children, extraStyle, isMarginBottom }) => {
  return (
    <StyledOuterContainer $isMarginBottom={isMarginBottom}>
      <StyledInnerContainer style={extraStyle}>{children}</StyledInnerContainer>
    </StyledOuterContainer>
  );
};

export default MaxWidthContainer;
