/* eslint-disable no-console */
import { call, put } from 'redux-saga/effects';

import { statusCodes } from '@src/lib/constants';

import actionConstants from '../constants';

// ------------------------------------
// Common Worker Saga
// ------------------------------------

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function* workerSaga(action: any): any {
  const { serviceMethod, actionTypeSuccess, actionTypeFailure, actionTypeRequest, extra, callback } = action.payload;

  try {
    yield put({
      type: actionTypeRequest,
      payload: extra,
    });

    yield put({
      type: actionConstants.START_LOADER,
      payload: { actionType: actionTypeRequest, loaderMessage: 'hello' },
    });

    const apiResponse = yield call(serviceMethod);
    const body = apiResponse.body ? apiResponse.body : apiResponse.data ? apiResponse.data : {};

    yield put({
      type: actionConstants.STOP_LOADER,
      payload: { actionType: actionTypeRequest },
    });

    yield put({
      type: actionTypeSuccess,
      payload: { body, extra },
    });

    if (callback) callback();
  } catch (error: any) {
    yield put({
      type: actionConstants.STOP_LOADER,
      payload: { actionType: actionTypeRequest },
    });

    if (error && error.response && error.response.status === statusCodes.API_ERROR_CODE) {
      // When Backend will be updated this will change.
      yield put({
        type: actionTypeFailure,
        payload: { body: error.response.body, extra },
      });
    }
  }
}
