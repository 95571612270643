const DeleteIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='21' height='20' viewBox='0 0 21 20' fill='none'>
      <path
        d='M18.1667 4.98332C15.3917 4.70832 12.6001 4.56665 9.81675 4.56665C8.16675 4.56665 6.51675 4.64998 4.86675 4.81665L3.16675 4.98332'
        stroke='#D93400'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.75 4.14175L7.93333 3.05008C8.06667 2.25841 8.16667 1.66675 9.575 1.66675H11.7583C13.1667 1.66675 13.275 2.29175 13.4 3.05841L13.5833 4.14175'
        stroke='#D93400'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.3752 7.6167L15.8335 16.0084C15.7418 17.3167 15.6668 18.3334 13.3418 18.3334H7.99183C5.66683 18.3334 5.59183 17.3167 5.50016 16.0084L4.9585 7.6167'
        stroke='#D93400'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.27515 13.75H12.0501'
        stroke='#D93400'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.5835 10.4167H12.7502'
        stroke='#D93400'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default DeleteIcon;
