import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import Tooltip from '@mui/material/Tooltip';

import { getAddOnsById } from '@src/api/addOns';
import { getBoxById } from '@src/api/box';
import { getDishById } from '@src/api/dish';
import useMediaQuery from '@src/hooks/useMediaQuery';
import { DROP_OFF, isAddonCategory, orderSummaryStatus, StatusType, UiRoutes } from '@src/lib/constants';
import { getAllergensIcon, isBoxCategory } from '@src/lib/helper';
import { addingIcon, rightArrowIcon, subtractIcon } from '@src/lib/imgUrl';
import { Box } from '@src/model/Box';
import { AddOn, Dish } from '@src/model/Event';
import { WeeklyDish } from '@src/model/WeeklyMenu';
import { ReduxProps } from '@src/redux/type';

import { StyledWidthProvider } from '../DeliveryDetails/styles';
import { StyledEditInput } from '../InvoiceCard/style';
import Loader from '../Loader';
import MoreInfo from '../Modals/MoreInfo';
import RateStarsLinkContainer from '../RateUsButton/rateuslink';

import {
  StyledAddPortion,
  StyledAllergenContainer,
  StyledAllergensImage,
  StyledBrandName,
  StyledDishContainer,
  StyledDishContent,
  StyledDishHeader,
  StyledDishImage,
  StyledDishNameContainer,
  StyledDivider,
  StyledExtraPortion,
  StyledFlexContainer,
  StyledItemCountContainer,
  StyledItemCountWrapper,
  StyledMoreInfoContainer,
  StyledMoreInfoWrapper,
  StyledRatingContainer,
  StyledRoundCheckbox,
  StyledSpanText,
  StyledTextWithEllipsis,
} from './styles';
import {
  DishItemProps,
  GetIsDefaultDishFullProps,
  IsItemSelectedOrDefaultProps,
  UseItemCountHandlerProps,
} from './types';

export interface OpenTooltips {
  [key: number]: boolean;
}

// eslint-disable-next-line complexity
const DishItem: React.FC<DishItemProps> = ({
  dishName,
  mealDetails,
  dishPrice,
  dishBrand,
  dishImage,
  dishInfo,
  minQuantity,
  allergens,
  dishID,
  multiplyBy,
  dishCount,
  selectedDish = [],
  handleCheckbox,
  handleDishItemCount,
  isRateUs = true,
  averageRating = 0,
  totalFeedbacks,
  dishCategory = '',
  serviceType,
}) => {
  const navigate = useNavigate();
  const isDesktop = useMediaQuery(650);
  const { bookingId } = useParams();

  const { makeMyOwn, eventStatus, packageDetails } = useSelector((state: ReduxProps) => state?.eventDetailsReducer);
  const [itemCount, setItemCount] = useState({ showAddPortion: dishCount ? true : false, dishCount });
  const [openTooltips, setOpenTooltips] = useState<OpenTooltips>({});

  const [dishData, setDishData] = useState<WeeklyDish>();
  const [loading, setLoading] = useState(false);
  const [isEditable, setIsEditable] = useState<boolean>(false);

  const [boxData, setBoxData] = useState<Box>();

  //ensured switch from button to input field
  const handleEditOn = () => setIsEditable(true);

  const selectedDishCategory =
    serviceType === DROP_OFF ? dishCategory || mealDetails?.name : mealDetails?.name || dishCategory;

  const isEditedBySales = !orderSummaryStatus.includes(eventStatus?.name as StatusType);

  const isAddon = mealDetails?.name || dishCategory ? isAddonCategory(mealDetails?.name || dishCategory) : false;

  const isSelected = isItemSelectedOrDefault({ selectedDish, dishID, isAddon, checkDefault: false });

  const isDefault = isItemSelectedOrDefault({
    selectedDish,
    dishID,
    isAddon,
    checkDefault: packageDetails?.service !== DROP_OFF,
  });

  const isDefaultDishFull =
    packageDetails?.service === DROP_OFF ? false : getIsDefaultDishFull({ mealDetails, selectedDish });

  const isBox = isBoxCategory(dishCategory || mealDetails?.name || '');

  const { itemCountHandler, handleInputChange, handleUpdate } = useItemCountHandler({
    dishID,
    handleCheckbox,
    handleDishItemCount,
    serviceType,
    isSelected,
    itemCount: itemCount?.dishCount,
    minQuantity,
    multiplyBy,
    setItemCount,
    dishCategory: selectedDishCategory,
  });

  const fetchDishData = async () => {
    setLoading(true);

    try {
      let result;

      if (isAddon) {
        result = await getAddOnsById(dishID);
      } else {
        result = await getDishById(dishID);
      }

      setDishData(result?.data);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const fetchBoxData = async (boxId: string) => {
    setLoading(true);

    try {
      const result = await getBoxById(boxId);
      setBoxData(result?.data);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleTooltipToggle = (index: number, isOpen: boolean) => {
    setOpenTooltips(prevOpenTooltips => ({
      ...prevOpenTooltips,
      [index]: isOpen,
    }));
  };

  return (
    <StyledDishContainer $isSelected={isDefault || isSelected} $isDesktop={isDesktop}>
      <StyledDishImage $imageUrl={dishImage} $isDesktop={isDesktop}>
        {dishBrand && <StyledBrandName>by {dishBrand}</StyledBrandName>}
        {isRateUs && (
          <StyledRatingContainer>
            <RateStarsLinkContainer
              onClick={() =>
                navigate(
                  `${
                    isAddon
                      ? UiRoutes.FEEDBACK_ADDONS_DETAILS.split(':')[0]
                      : UiRoutes.FEEDBACK_DISH_DETAILS.split(':')[0]
                  }${dishID}?serviceType=${serviceType}&bookingId=${bookingId}`
                )
              }
              headerText={isAddon ? 'Addon' : 'Dish'}
              rating={averageRating}
              totalFeedbacks={totalFeedbacks}
            />
          </StyledRatingContainer>
        )}
      </StyledDishImage>
      <StyledDishContent>
        <StyledDishHeader>
          <StyledDishNameContainer $isRateUs={isRateUs}>
            {dishName}
            {allergens && allergens.length > 0 ? (
              <StyledAllergenContainer>
                {allergens &&
                  allergens.length > 0 &&
                  allergens.map((allergen, index) => {
                    return (
                      <ClickAwayListener onClickAway={() => handleTooltipToggle(index, false)}>
                        <Tooltip
                          key={`${allergen?._id}+${index}`}
                          title={allergen?.name}
                          placement='right-start'
                          onClose={() => handleTooltipToggle(index, false)}
                          open={openTooltips[index] || false}
                          onMouseEnter={() => handleTooltipToggle(index, true)}
                          onMouseLeave={() => handleTooltipToggle(index, false)}
                        >
                          <div>
                            <StyledAllergensImage
                              onClick={() => handleTooltipToggle(index, true)}
                              src={allergen?.icon?.url || getAllergensIcon(allergen?.name)}
                              alt={allergen?._id}
                            />
                          </div>
                        </Tooltip>
                      </ClickAwayListener>
                    );
                  })}
              </StyledAllergenContainer>
            ) : null}
          </StyledDishNameContainer>
          {!makeMyOwn && (isDefaultDishFull || isDefault) ? (
            <StyledRoundCheckbox
              type='checkbox'
              value={dishID}
              checked={isDefault}
              onChange={({ target }) =>
                handleCheckbox?.({
                  id: target?.value,
                  isChecked: target?.checked,
                  category: selectedDishCategory || '',
                })
              }
            />
          ) : null}
        </StyledDishHeader>
        {isRateUs ? <StyledDivider /> : null}
        <StyledTextWithEllipsis $isRateUs={isRateUs}>{dishInfo}</StyledTextWithEllipsis>
        {!isRateUs && (
          <StyledMoreInfoContainer>
            <StyledMoreInfoWrapper onClick={isBox ? () => fetchBoxData(dishID) : () => fetchDishData()}>
              <StyledSpanText>More Info</StyledSpanText>
              <img src={rightArrowIcon} alt='right arrow' />
            </StyledMoreInfoWrapper>
            {isEditedBySales && (makeMyOwn || selectedDish.length >= (mealDetails?.allowedDishes || 0)) ? (
              !makeMyOwn && !itemCount?.showAddPortion ? (
                <StyledAddPortion onClick={() => setItemCount(prev => ({ ...prev, showAddPortion: true }))}>
                  Add Extra Portion
                </StyledAddPortion>
              ) : (
                <StyledItemCountWrapper>
                  <StyledExtraPortion>
                    AED {dishPrice} per portion (min {minQuantity || 1})
                  </StyledExtraPortion>
                  <StyledFlexContainer>
                    <StyledItemCountContainer
                      $isDisable={makeMyOwn ? false : isDefaultDishFull}
                      disabled={makeMyOwn ? false : isDefaultDishFull}
                      onClick={() => {
                        itemCountHandler('decrease');
                      }}
                      $minus
                    >
                      <img src={subtractIcon} alt='decrease' />
                    </StyledItemCountContainer>

                    {isEditable ? (
                      <StyledWidthProvider width='60px'>
                        <StyledEditInput
                          value={itemCount.dishCount || ''}
                          onChange={handleInputChange}
                          onKeyDown={handleUpdate}
                        />
                      </StyledWidthProvider>
                    ) : (
                      <StyledItemCountContainer
                        $isDisable={makeMyOwn ? false : isDefaultDishFull}
                        $count
                        onClick={handleEditOn}
                      >
                        {dishCount}
                      </StyledItemCountContainer>
                    )}

                    <StyledItemCountContainer
                      $isDisable={makeMyOwn ? false : isDefaultDishFull}
                      disabled={makeMyOwn ? false : isDefaultDishFull}
                      onClick={() => {
                        itemCountHandler('increase');
                      }}
                      $add
                    >
                      <img src={addingIcon} alt='increase' />
                    </StyledItemCountContainer>
                  </StyledFlexContainer>
                </StyledItemCountWrapper>
              )
            ) : null}
          </StyledMoreInfoContainer>
        )}
      </StyledDishContent>
      {dishData || boxData ? (
        <MoreInfo
          handleClose={() => {
            isBox ? setBoxData(undefined) : setDishData(undefined);
          }}
          showModal={!!(dishData || boxData)}
          dishData={isBox ? boxData : dishData}
          serviceType={packageDetails?.service}
          allergens={allergens}
          isBox={isBox}
        />
      ) : null}
      {loading ? <Loader /> : null}
    </StyledDishContainer>
  );
};

export default DishItem;

const useItemCountHandler = ({
  itemCount,
  minQuantity,
  multiplyBy,
  isSelected,
  isDefault,
  dishID,
  handleCheckbox,
  handleDishItemCount,
  setItemCount,
  serviceType,
  dishCategory,
}: UseItemCountHandlerProps) => {
  const { makeMyOwn } = useSelector((state: ReduxProps) => state?.eventDetailsReducer);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!(typeof itemCount === 'number' && handleCheckbox && handleDishItemCount)) return;

    let newCount = itemCount || 0;

    if (event) {
      newCount = Number(event.target.value);
    }

    setItemCount(prev => ({
      ...prev,
      dishCount: newCount,
    }));
  };

  const handleUpdate = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key == 'Enter') {
      if (!(typeof itemCount === 'number' && handleCheckbox && handleDishItemCount)) return;

      const newCount = itemCount || 0;

      if (isSelected && !isDefault && newCount <= 0) {
        handleCheckbox({
          id: dishID,
          isChecked: false,
          selectItemCount: 0,
          category: dishCategory || '',
        });
      } else if (!isSelected && !isDefault && newCount > 0) {
        handleCheckbox({
          id: dishID,
          isChecked: true,
          selectItemCount: newCount,
          category: dishCategory || '',
        });
      } else {
        handleDishItemCount({ id: dishID, itemCount: newCount, category: dishCategory || '' });
      }

      setItemCount(prev => ({
        ...prev,
        dishCount: newCount,
      }));
    }
  };

  const handleIncrement = (newCount: number) => {
    if (!isSelected && (serviceType === DROP_OFF || !isDefault)) {
      handleCheckbox?.({
        id: dishID,
        isChecked: true,
        selectItemCount: newCount,
        category: dishCategory ?? '',
      });
    } else {
      handleDishItemCount?.({
        id: dishID,
        itemCount: newCount,
        category: dishCategory || '',
      });
    }
  };

  const handleDecrement = (newCount: number) => {
    if (isSelected && !isDefault && newCount <= 0) {
      if (serviceType === DROP_OFF || makeMyOwn) {
        handleCheckbox?.({
          id: dishID,
          isChecked: false,
          selectItemCount: 0,
          category: dishCategory ?? '',
        });
        handleDishItemCount?.({ id: dishID, itemCount: newCount, category: dishCategory ?? '' }); //todo
      } else {
        handleCheckbox?.({
          id: dishID,
          isChecked: false,
          selectItemCount: newCount,
          category: dishCategory ?? '',
        });
      }
    } else {
      handleDishItemCount?.({ id: dishID, itemCount: newCount, category: dishCategory || '' });
    }
  };

  const itemCountHandler = (action: string) => {
    if (!(typeof itemCount === 'number' && handleCheckbox && handleDishItemCount && setItemCount)) return;

    const minimumQuantity = minQuantity || 1;
    const increment = itemCount >= minimumQuantity ? multiplyBy || 1 : minimumQuantity;

    let newCount = itemCount || 0;

    if (action === 'increase') {
      newCount = itemCount + increment;
      handleIncrement(newCount);
    } else if (action === 'decrease') {
      newCount = itemCount - increment;

      if (newCount < minimumQuantity) newCount = 0;

      handleDecrement(newCount);
    }

    setItemCount?.(prev => ({ ...prev, dishCount: newCount }));
  };

  return {
    itemCountHandler,
    handleInputChange,
    handleUpdate,
  };
};

const getIsDefaultDishFull = ({ selectedDish, mealDetails }: GetIsDefaultDishFullProps): boolean => {
  const filterItems = (items: (Dish | AddOn)[]) =>
    items.filter((item: Dish | AddOn) => item.category?.name === mealDetails?.name && item.includedInPackage).length;

  const value = selectedDish ? filterItems(selectedDish as Dish[] | AddOn[]) : 0;

  return value < Number(mealDetails?.allowedDishes);
};

const isItemSelectedOrDefault = ({
  checkDefault,
  dishID,
  isAddon,
  selectedDish,
}: IsItemSelectedOrDefaultProps): boolean => {
  return selectedDish.some(item => {
    if (item != null) {
      const isBox = isBoxCategory(item?.category?.name || '');
      const itemId = isAddon ? (item as AddOn).addOnId : isBox ? (item as Box)._id : (item as Dish).dishId;
      const isIncluded = checkDefault ? (item as Dish | AddOn).includedInPackage : true;
      return itemId === dishID && isIncluded;
    }
  });
};
