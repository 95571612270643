import styled from 'styled-components';

import { TextField } from '@mui/material';

import { Breakpoints } from '@src/styles/breakpoints';
import { theme } from '@src/styles/theme';

export const StyledButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-self: end;
  gap: 16px;
  margin: ${theme.fontSize.get('l')};
`;

export const StyledToggleFieldContainer = styled.div`
  display: flex;
  padding: 4px 0px;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;

export const StyledError = styled.div`
  color: ${theme.primaryColor.red};
  line-height: 1.66;
  font-size: ${theme.fontSize.get('2xs')};
  letter-spacing: 0.00033em;
  margin: 3px 14px 0 14px;
  &::first-letter {
    text-transform: capitalize;
  }
`;

export const StyledPhoneInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  .country-name {
    display: none;
  }
`;

export const StyledPhoneInputButton = styled.div`
  width: 90%;
  border-radius: 8px;
  background-color: transparent;
  border: 1px solid var(--Brand-Palette-Gray-200, ${theme.primaryColor.lunarElegance});
`;

export const StyledButton = styled.button<{
  $bgColor?: string;
  color?: string;
  border?: boolean;
  display?: string;
  $isBlueButton?: boolean;
  margin?: string;
  borderColor?: string;
}>`
  width: fit-content;
  align-self: center;
  border-radius: 8px;
  border: ${({ border, borderColor }) =>
    border ? (borderColor ? `1px solid ${borderColor}` : `1px solid ${theme.primaryColor.lightGray2}`) : 'none'};
  background-color: ${({ $bgColor }) => ($bgColor ? $bgColor : theme.primaryColor.shadePurple)};
  color: ${({ color }) => (color ? color : theme.primaryColor.white)};
  text-align: center;
  padding: 12px 27px;
  font-family: ${theme.fontFamily.lato}, ${theme.fontFamily.seasons};
  margin: ${({ margin }) => margin};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  display: ${({ display }) => display};
  ${({ $isBlueButton }) => ($isBlueButton ? `padding:12px 34px ;` : ``)};

  &:disabled {
    background-color: ${theme.primaryColor.aquaBlue};
    cursor: not-allowed;
  }
`;

export const StyledHeading = styled.div`
  font-size: ${theme.fontSize.get('m')};
  font-weight: 600;
  font-family: ${theme.fontFamily.lato}, ${theme.fontFamily.seasons};
  padding-bottom: 32px;
`;

export const StyledLabel = styled.div<{ $isCheckBox?: boolean }>`
  font-family: ${theme.fontFamily.lato}, ${theme.fontFamily.seasons};
  color: ${theme.primaryColor.mutedGray};
  font-weight: 500;
  padding-bottom: ${({ $isCheckBox }) => ($isCheckBox ? '' : '8px')};
  font-size: ${theme.fontSize.get('xs')};
`;

export const StyledCheckBox = styled.input`
  width: 16px;
  height: 16px;
  margin-left: 0px;
`;

export const StyledCheckBoxContainer = styled.div`
  display: flex;
  padding: 4px 0px;
  align-items: center;
  gap: 10px;
`;

export const StyledInputFieldContainer = styled.div<{ $gridColumn?: string; $gridRow?: string }>`
  grid-column: ${({ $gridColumn }) => $gridColumn && $gridColumn};
  grid-row: ${({ $gridRow }) => $gridRow && $gridRow};
  padding: 0px 16px;
  ${Breakpoints.TABLET} {
    grid-column: 1/1;
    grid-row: auto;
  }
`;
export const StyledInput = styled(TextField)<{ $gridColumn?: string; $gridRow?: string; rows?: number }>`
  width: 100%;
  grid-column: ${({ $gridColumn }) => $gridColumn && $gridColumn};
  grid-row: ${({ $gridRow }) => $gridRow && $gridRow};

  flex: 1;
  .MuiOutlinedInput-notchedOutline {
    border: 1px solid var(--Brand-Palette-Gray-200, ${theme.primaryColor.lunarElegance});
    border-radius: 6px;
  }
  .MuiFormHelperText-root {
    color: ${theme.primaryColor.red};
    font-family: ${theme.fontFamily.lato}, ${theme.fontFamily.seasons};
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .MuiInputBase-root {
    ${({ rows }) => (rows ? `min-height: 44px;` : `height: 44px;`)}
    background-color: ${theme.primaryColor.pristineSnowfall};
  }
  .MuiFormHelperText-root {
    color: ${theme.primaryColor.red};
  }
  .MuiInputLabel-root {
    color: ${theme.primaryColor.darkBlue};
    font-size: ${theme.fontSize.get('s')};
    top: -6px;
  }
  & .MuiInputLabel-root.MuiInputLabel-shrink {
    top: 0;
  }

  @media (max-width: 700px) {
    grid-column: 1 / span 1;
  }
`;

export const StyledGridbox = styled.div<{ $gridColumn?: string; $columnGap?: string }>`
  display: grid;
  grid-template-columns: ${({ $gridColumn }) => ($gridColumn ? $gridColumn : 'repeat(3, 1fr)')};
  gap: 24px;
  ${({ $columnGap }) => $columnGap && `column-gap: ${$columnGap};`}
  ${Breakpoints.TABLET} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const StyledSection = styled.div<{ border?: boolean; $isMargin?: boolean }>`
  background-color: ${({ theme }) => theme.primaryColor.white};
  ${({ border, theme }) =>
    border && border
      ? `border: 1px solid var(--Primary-variant, ${theme.primaryColor.softBlue});border-radius: 16px;`
      : ''}
  padding: ${({ theme }) => theme.fontSize.get('l')};
  margin: ${({ theme, $isMargin }) => (!$isMargin ? theme.fontSize.get('l') : 0)};
  gap: 24px;
`;

export const StyledControlsContainer = styled.div<{ $isContainSpace?: boolean }>`
  background-color: ${({ theme }) => theme.primaryColor.whiteSmoke};
  padding: ${({ $isContainSpace }) => (!$isContainSpace ? '1% 8%' : '0')};
  margin-bottom: ${({ $isContainSpace }) => ($isContainSpace ? '3%' : '0')};
  @media (max-width: 600px) {
    padding: ${({ $isContainSpace }) => (!$isContainSpace ? '0.5%' : '0')};
  }
`;

export const StyledForm = styled.form<{ $removeOverflow?: boolean }>`
  display: flex;
  flex-direction: column;
  ${({ $removeOverflow }) => ($removeOverflow ? '' : `overflow-y: auto;`)}
`;

export const StyledSubHeading = styled.div`
  font-weight: 500;
  font-size: ${theme.fontSize.get('m')};
  color: ${theme.primaryColor.mutedGray};
  padding: 16px;
  padding-bottom: 8px;
`;

export const StyledSelectOuter = styled(StyledInput)<{ $isMulti?: boolean; $isError?: boolean; label?: string }>`
  position: absolute !important;
  .MuiFormLabel-root {
    top: -7px;
  }
  .MuiInputLabel-shrink {
    top: 0px;
  }
  ${({ $isMulti }) =>
    $isMulti &&
    `.MuiInputBase-root {
    height: inherit;
    min-height:44px;
  }
  height: 100%;`}
  input {
    display: none;
  }
  width: 100%;
  label {
    color: ${props =>
      props.$isError
        ? props.theme.primaryColor.red
        : props.theme.primaryColor.mutedGray}; // Change color to red when $isError is true
  }
`;

export const StyledDatePickerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledLabelContainer = styled.div<{ $showInline: boolean }>`
  ${({ $showInline }) =>
    $showInline &&
    `
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
  `}
`;

export const StyledHeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const extraStyles = {
  '@media screen and (max-width: 890px)': {
    '& label': {
      color: `${theme.primaryColor.white} !important`,
    },
    '& label.Mui-focused': {
      color: theme.primaryColor.white,
    },
    '& .MuiInputBase-root': {
      backgroundColor: 'transparent',
      color: theme.primaryColor.white,
      backdropFilter: `blur(${theme.fontSize.get('xl')})`,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: theme.primaryColor.white,
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.primaryColor.white,
      },
    },
    '& input:-webkit-autofill': {
      WebkitTextFillColor: `${theme.primaryColor.white} !important`,
    },
  },
  '& input:-webkit-autofill': {
    WebkitBoxShadow: '0 0 0 100px transparent inset !important',
    transition: 'background-color 5000s ease-in-out 0s',
  },
};

export const StyledDateTimeContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const StyledTableHeaderText = styled.th`
  text-align: center;
  padding-bottom: 20px;
  ${Breakpoints.TABLET} {
    font-size: ${({ theme }) => theme.fontSize.get('xs')};
  }
`;

export const StyledTable = styled.table`
  background-color: ${({ theme }) => theme.primaryColor.white};
  border: 1px solid ${({ theme }) => theme.primaryColor.softBlue};
  width: 100%;
  border-radius: 10px;
  padding: 20px 0px;
  margin-top: 12px;
`;

export const StyledHeaderName = styled(StyledTableHeaderText)``;

export const StyledHeaderSequence = styled(StyledTableHeaderText)``;

export const StyledHeaderAction = styled.th`
  padding-bottom: 10px;
  ${Breakpoints.TABLET} {
    font-size: ${({ theme }) => theme.fontSize.get('xs')};
  }
`;

const StyledTableDescription = styled.td`
  text-align: center;
  padding: 12px 0;
  margin-left: 16px;
  ${Breakpoints.TABLET} {
    font-size: ${({ theme }) => theme.fontSize.get('xs')};
  }
`;

export const StyledDataName = styled(StyledTableDescription)``;
export const StyledDataSequence = styled(StyledTableDescription)``;
export const StyledCopyURL = styled.div`
  display: flex;
  justify-content: center;
  padding: 12px 0;
  ${Breakpoints.TABLET} {
    font-size: ${({ theme }) => theme.fontSize.get('xs')};
  }
`;

export const StyledEditButton = styled.div`
  cursor: pointer;
`;
export const StyledURLContainer = styled.div<{ $gridColumn?: string; $gridRow?: string }>`
  grid-column: ${({ $gridColumn }) => $gridColumn && $gridColumn};
  grid-row: ${({ $gridRow }) => $gridRow && $gridRow};
  ${Breakpoints.TABLET} {
    grid-column: 1/1;
    grid-row: auto;
  }
`;
