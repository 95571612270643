import { Booking, Event } from '@src/model/Event';

import ActionConstants from '../constants';

export const createEventAction = (payload: Event) => {
  return {
    type: ActionConstants.CREATE_EVENT_DETAILS,
    payload,
  };
};

export const updateEventAction = (payload: Event) => {
  return {
    type: ActionConstants.UPDATE_EVENT_DETAILS,
    payload,
  };
};

export const addNewBooking = (payload: Booking) => {
  return {
    type: ActionConstants.ADD_MORE_BOOKING,
    payload,
  };
};

export const updateBooking = (payload: Booking[]) => {
  return {
    type: ActionConstants.DELETE_BOOKING,
    payload,
  };
};

export const resetBooking = () => {
  return {
    type: ActionConstants.RESET_BOOKING,
  };
};

export const showMessage = () => {
  return {
    type: ActionConstants.SHOW_MESSAGE,
  };
};

export const clearEventDetails = () => {
  return {
    type: ActionConstants.CLEAR_EVENT_DETAILS,
  };
};
