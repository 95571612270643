import apiEndpoints from '@src/lib/apiEndpoints';
import { Params } from '@src/lib/getOptions';
import { DataProps } from '@src/lib/getUpdate';

import apiRequest from './api';

export const getEventById = (params: Params, id: string, skipErrorMessage?: boolean) => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.GET_EVENT}/${id}`,
    config: { params, skipErrorMessage },
  });
};

export const updateEvent = (data: DataProps, id: string) => {
  return apiRequest({
    method: 'PUT',
    url: `${apiEndpoints.GET_EVENT}/${id}`,
    data,
  });
};

export const getEventHistory = (params: Params) => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.GET_EVENT_HISTORY}`,
    config: { params },
  });
};
