import styled from 'styled-components';

import { Breakpoints } from '@src/styles/breakpoints';

export const StyledModalContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: white;
  transform: translate(-50%, -50%);
  padding: 24px;
  border: none;
  border-radius: 10px;
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  &:focus {
    outline: none;
  }
  ${Breakpoints.TABLET} {
    width: 60%;
  }
  ${Breakpoints.MOBILE} {
    width: 80%;
  }
`;

export const StyledButtonWrapper = styled.div`
  display: flex;
  gap: 20px;
`;

export const StyledContentWrapper = styled.div`
  line-height: 25px;
`;
