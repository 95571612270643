import ActionConstants from '../constants';
import { UpdateInvoiceProps } from '../type';

export const updateInvoiceAction = (payload: UpdateInvoiceProps) => {
  return {
    type: ActionConstants.UPDATE_INVOICE_DETAILS,
    payload,
  };
};

export const clearInvoiceDetails = () => {
  return {
    type: ActionConstants.CLEAR_INVOICE_DETAILS,
  };
};
